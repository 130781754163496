import {
  filterUserViewDropdownItem,
  getSessionStorage,
  hasDefaultModuleView,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  RAFEntityName,
  RAFReportType,
  StorageKey,
} from "../../../../RAFComponents/helpers/Constants";
import {
  hasPermission,
  hasPermissions,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  getLeftMenuItems,
  getModuleAvatarIcon,
} from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { MenuRow } from "../../../../RAFComponents/models/Common/RAFMenuModel";
import { RAFViewRow } from "../../../../RAFComponents/models/Common/RAFViewRow";
import { RAFWorkspaceRelatedtoType } from "../../../../RAFMaster/helpers/RMConstants";
import { RoleRow } from "../../Role/RoleRow";
import { WorkspaceRow } from "../WorkspaceRow";
import { WorkspaceItemRow } from "../WorkspaceService/WorkspaceItemRow";

export class PortalTreeViewItemRow {
  UID: string;
  Name: string;
  Text: string;
  Icon: string;
  URL: string;
  Entity: string;
  ParentUID: string;
  ItemType?: string;
  HasChildren: boolean;
  IsExternalIcon: boolean;
  NodeChild: PortalTreeViewItemRow[];
  PermissionName?: string;
  PermissionNames?: string[];
}

export const getLeftNavigationMenuItems = (
  currentWorkspace: WorkspaceRow,
  workspaceItems: WorkspaceItemRow[],
  permissionValue: RoleRow
) => {
  const allPortalTreeViewItems = getWorkSpaceLeftMenuItems(
    currentWorkspace,
    workspaceItems,
    permissionValue
  );

  const portalTreeViewItems = filterItemByModuleReadPermission(
    allPortalTreeViewItems,
    permissionValue
  );
  return portalTreeViewItems;
};

const filterItemByModuleReadPermission = (
  portalTreeViewItems: PortalTreeViewItemRow[],
  permissionValue: RoleRow
) => {
  if (
    isNotEmptyArray(portalTreeViewItems) &&
    isNotNullAndUndefined(permissionValue)
  ) {
    const newPortalTreeViewItems = portalTreeViewItems.filter((x) => {
      if (isNotEmptyArray(x.PermissionNames)) {
        const permissions = hasPermissions(permissionValue, x.PermissionNames);
        if (permissions) {
          return x;
        }
      } else if (isNotNullAndUndefined(x.Entity)) {
        const permission = hasPermission(permissionValue, `${x.Entity}::List`);
        if (permission) {
          return x;
        }
      } else {
        return x;
      }
    });
    return newPortalTreeViewItems;
  } else {
    return portalTreeViewItems;
  }
};

const getWorkSpaceLeftMenuItems = (
  currentWorkspace: WorkspaceRow,
  workspaceItems: WorkspaceItemRow[],
  permissionValue: RoleRow
) => {
  //to get left menu portal date
  const leftDefaultMenuItems: MenuRow[] = getLeftMenuItems(
    currentWorkspace,
    workspaceItems
  );

  const portalTreeViewItems = getPortalLeftMenuTreeViewItems(
    leftDefaultMenuItems,
    permissionValue
  );

  return portalTreeViewItems;
};

export const getPortalLeftMenuTreeViewItems = (
  menuItems: MenuRow[],
  permissionValue: RoleRow
) => {
  //conver to tree view and get child items of module
  const portalTreeViewItems: PortalTreeViewItemRow[] = [];
  if (isNotEmptyArray(menuItems)) {
    menuItems.forEach((item) => {
      if (isNotNullAndUndefined(item.ParentUID)) {
        //isParentExist condition needed
      } else {
        const childItems = isNotNullAndUndefined(item.UID)
          ? menuItems.filter((x) => x.ParentUID === item.UID)
          : [];
        const treeViewChildItems: PortalTreeViewItemRow[] = [];
        if (isNotEmptyArray(childItems)) {
          childItems.forEach((child) => {
            const treeViewChildItem = convertMenuRowToWorkspaceViewItem(
              child,
              []
            );
            if (isNotNullAndUndefined(treeViewChildItem)) {
              treeViewChildItems.push(treeViewChildItem);
            }
          });
        } else if (
          item.itemType === RAFWorkspaceRelatedtoType.Module &&
          item.HasChildren === true
        ) {
          const childViewItems = getViewItemsForCurrentMenuFromSessionStorage(
            item,
            permissionValue
          );
          if (isNotEmptyArray(childViewItems)) {
            //check view permissions here
            childViewItems.forEach((child) => {
              if (isNotNullAndUndefined(child)) {
                treeViewChildItems.push(child);
              }
            });
          }
        }
        const treeViewItem = convertMenuRowToWorkspaceViewItem(
          item,
          treeViewChildItems
        );
        if (isNotNullAndUndefined(treeViewItem)) {
          portalTreeViewItems.push(treeViewItem);
        }
      }
    });
  }
  return portalTreeViewItems;
};

export const convertMenuRowToWorkspaceViewItem = (
  menuItem: MenuRow,
  treeViewChildItems: PortalTreeViewItemRow[]
) => {
  //convert menurow to PortalTreeViewItemRow
  let treeViewItem: PortalTreeViewItemRow = null;
  if (isNotNullAndUndefined(menuItem)) {
    treeViewItem = {
      UID: menuItem.UID ?? menuItem.Name,
      Name: menuItem.Name,
      Text: menuItem.Text,
      Icon: menuItem.Icon,
      URL: menuItem.Url,
      Entity: menuItem.Entity,
      ParentUID: menuItem.ParentUID,
      IsExternalIcon: menuItem.isExternalIcon ?? false,
      ItemType: menuItem.itemType,
      NodeChild: isNotEmptyArray(treeViewChildItems) ? treeViewChildItems : [],
      HasChildren: isNotNullAndUndefined(menuItem.HasChildren)
        ? menuItem.HasChildren
        : isNotEmptyArray(treeViewChildItems) ||
          menuItem.itemType === RAFWorkspaceRelatedtoType.Module
        ? true
        : false,
      PermissionNames: menuItem.permissions ?? [],
    };
  }
  return treeViewItem;
};

export const getViewItemsForCurrentMenuFromSessionStorage = (
  menuItem: MenuRow,
  permissionValue: RoleRow
) => {
  // get view from SessionStorage to bind data in initial load
  let portalTreeViewItems: PortalTreeViewItemRow[] = [];
  if (isNotNullAndUndefined(menuItem)) {
    const viewsStorage = getSessionStorage(
      StorageKey.allViews_modulename + menuItem.Entity,
      true
    );
    const allViews: RAFViewRow[] = !IsNullOrWhiteSpace(viewsStorage)
      ? JSON.parse(viewsStorage)
      : null;

    const addDefaultView = hasDefaultModuleView(menuItem.Entity) ? true : false;

    const userViewDropdownItem = filterUserViewDropdownItem(
      menuItem.Entity,
      addDefaultView,
      addDefaultView ? [] : allViews,
      true
    );
    const defaultView = isNotEmptyArray(userViewDropdownItem)
      ? userViewDropdownItem.filter(
          (x) =>
            x.ViewType === RAFReportType.Default ||
            x.ViewType === RAFReportType.Table
        )
      : [];
    portalTreeViewItems = convertViewRowToWorkspaceViewItem(
      defaultView,
      menuItem.Entity,
      menuItem.UID ?? menuItem.Name,
      menuItem.Url,
      permissionValue
    );
  }
  return portalTreeViewItems;
};

export const convertViewRowToWorkspaceViewItem = (
  viewItems: RAFViewRow[],
  entity: string,
  parentUID: string,
  defaultURL: string,
  permissionValue: RoleRow
) => {
  //convert view row to PortalTreeViewItemRow
  let portalTreeViewItem: PortalTreeViewItemRow[] = [];
  if (isNotEmptyArray(viewItems)) {
    viewItems.forEach((item) => {
      const permissionName = item.PermissionName ?? `${item.Entity}::list`;
      const hasPermissionToView = isNotNullAndUndefined(permissionValue)
        ? hasPermission(permissionValue, permissionName)
        : true;
      if (
        hasPermissionToView === true &&
        (item.ViewType === RAFReportType.Default ||
          item.ViewType === RAFReportType.Table)
      ) {
        let url = defaultURL;
        if (
          entity === RAFEntityName.InspectionLibraryCategoryType ||
          //entity === RAFEntityName.ProcessLibraryCategoryType ||
          entity === RAFEntityName.Migrate
        ) {
          if (isNotNullAndUndefined(item["url"])) {
            url = item["url"];
          }
        }

        portalTreeViewItem.push({
          UID: item.UID ?? item.ViewName,
          Name: item.ViewName,
          Text: item.DisplayName,
          Icon: getModuleAvatarIcon(entity),
          URL: url,
          Entity: entity,
          ParentUID: parentUID,
          IsExternalIcon: false,
          ItemType: RAFWorkspaceRelatedtoType.View,
          NodeChild: [],
          HasChildren: false,
          PermissionName: item.PermissionName ?? `${entity}::list`,
        });
      }
    });
  }
  return portalTreeViewItem;
};
