import { propertyOf } from '../../../RAFComponents/helpers/utils';

//export class TaskRow extends RAFEntityBase {

export enum TaskFilterValue {
    All = "All",
    Upcoming = "Upcoming",
    Overdue = "Overdue",
    Available = "Available",
    Completed = "Completed",
    CompletedByMe = "Completed By Me",
    CompletedByTeam = "Completed By Team",
    CompletedAssignedbyMe = "Completed Assignedby Me",
    Unplanned = "Unplanned",
    ASAP = "ASAP",
}

export enum CompletedTaskOptions {
    ShowAll = "Completed",
    CompletedByMe = 'Completed By Me',//"By Me",
    CompletedByTeam = 'Completed By Team',// "By Team",
    CompletedAssignedbyMe = "Assigned By Me",
}

export enum MyTaskOptions {
    All = "All",
    Today = "Today",
    Upcoming = "Upcoming",
    Overdue = "Overdue",
    AssignedToMe = "Assigned to my team",
    Unplanned = "Unplanned",
    ASAP = "ASAP",
}

export enum TaskDropdownItems {
    MyTask = "My Tasks",
    AssignedToMe = "Assigned to my team",
    AssignedbyMe = "Tasks Assigned by Me",
    Completed = "Completed Tasks",
    RecentlyUpdated = "Recently Updated",
}

export class TaskViewMenuItem {
    static UpcomingTasks = { text: "Upcoming Tasks", value: TaskDropdownItems.MyTask };
    static AssignedByMe = { text: "Assigned by Me", value: TaskDropdownItems.AssignedbyMe };
    static CompletedTasks = { text: "Completed Tasks", value: TaskDropdownItems.Completed };
}

export const taskFilterDropdownItem = [
    {
        id: TaskDropdownItems.MyTask,
        text: TaskDropdownItems.MyTask,
    },
    {
        id: TaskDropdownItems.AssignedToMe,
        text: TaskDropdownItems.AssignedToMe,
    },
    {
        id: TaskDropdownItems.AssignedbyMe,
        text: TaskDropdownItems.AssignedbyMe,
    },
    {
        id: TaskDropdownItems.Completed,
        text: TaskDropdownItems.Completed,
    },
    {
        id: TaskDropdownItems.RecentlyUpdated,
        text: TaskDropdownItems.RecentlyUpdated,
    },
];

export const myTaskDropdownItems = [
    {
        id: MyTaskOptions.All,
        text: MyTaskOptions.All,
    },
    {
        id: MyTaskOptions.ASAP,
        text: MyTaskOptions.ASAP,
    },
    // {
    //     id: MyTaskOptions.Today,
    //     text: MyTaskOptions.Today,
    // },
    // {
    //     id: MyTaskOptions.Upcoming,
    //     text: MyTaskOptions.Upcoming,
    // },
    {
        id: MyTaskOptions.Overdue,
        text: MyTaskOptions.Overdue,
    },
    {
        id: MyTaskOptions.Unplanned,
        text: MyTaskOptions.Unplanned,
    },
];

export const relatedTaskTabItems = [
    {
        id: MyTaskOptions.All,
        text: MyTaskOptions.All,
    },
    {
        id: MyTaskOptions.ASAP,
        text: MyTaskOptions.ASAP,
    },
    // {
    //     id: MyTaskOptions.Today,
    //     text: MyTaskOptions.Today,
    // },
    // {
    //     id: MyTaskOptions.Upcoming,
    //     text: MyTaskOptions.Upcoming,
    // },
    {
        id: MyTaskOptions.Overdue,
        text: MyTaskOptions.Overdue,
    },
    {
        id: MyTaskOptions.Unplanned,
        text: MyTaskOptions.Unplanned,
    },
    
];

export const completedTaskDropdownItems = [
    {
        id: TaskFilterValue.CompletedByMe,
        text: CompletedTaskOptions.CompletedByMe,
    },
    {
        id: TaskFilterValue.CompletedByTeam,
        text: CompletedTaskOptions.CompletedByTeam,
    },
    // {
    //     id: TaskFilterValue.CompletedAssignedbyMe,
    //     text: CompletedTaskOptions.CompletedAssignedbyMe,
    // },
];

export class MyTaskCount {
    All?: number;
    ASAP?: number;
    Today?: number;
    Delegated?: number;
    Upcoming?: number;
    Overdue?: number;
    Available?: number;
}

export class TaskReminder {
    UID?: string;
    ReminderType?: string;
    RemindDate?: Date;
    //RemindText?: string;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
}

export class ITaskDto {
    UID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
    TaskId?: string;
    Title?: string;
    TaskDate?: Date;
    EndDate?: Date;
    ActualStartDate?: Date;
    CompletedDate?: Date;
    TaskStatus?: string;
    AssigneeUID?: string;
    Assignee?: string;
    CompletedByUID?: string;
    CompletedBy?: string;
    AssignTeamUID?: string;
    AssignTeam?: string;
    IsNextTask?: boolean;
    IsApprovalTask?: boolean;
    HasReminders?: boolean;
    BPStepUID?: string;
    BPStep?: string;
    ParentUID?: string;
    Parent?: string;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
    Priority?: string;
    TaskType?: string;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToType?: string;
    TaskOrder?: number;
    Frequency?: string;
    CronExpression?: string;
    RRuleExpression?: string;
    Description?: string;
    CommentCount?: number;
    TagsListJson?: string[];
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    ProcessStatus?: string;
    IsRequired?: boolean;
    SortOrder?: number;
    IndependenceLevel?: string;
    IndependenceLevelScore?: number;
    Duration?: number;
    ActualDuration?: number;
    ServiceContractItem?: string;
    ServiceContractItemUID?: string;
}

export class ITaskRow {
    UID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
    TaskId?: string;
    Title?: string;
    TaskDate?: Date;
    EndDate?: Date;
    ActualStartDate?: Date;
    CompletedDate?: Date;
    TaskStatus?: string;
    AssigneeUID?: string;
    Assignee?: string;
    CompletedByUID?: string;
    CompletedBy?: string;
    AssignTeamUID?: string;
    AssignTeam?: string;
    IsNextTask?: boolean;
    IsApprovalTask?: boolean;
    HasReminders?: boolean;
    BPStepUID?: string;
    BPStep?: string;
    ParentUID?: string;
    Parent?: string;
    RelatedToUID?: string;
    RelatedTo?: string;
    RelatedToType?: string;
    Priority?: string;
    TaskType?: string;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToType?: string;
    TaskOrder?: number;
    Frequency?: string;
    CronExpression?: string;
    RRuleExpression?: string;
    Description?: string;
    CommentCount?: number;
    TagsListJson?: string[];
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    ProcessStatus?: string;
    IsRequired?: boolean;
    SortOrder?: number;
    IndependenceLevel?: string;
    IndependenceLevelScore?: number;
    Duration?: number;
    ActualDuration?: number;
    ServiceContractItem?: string;
    ServiceContractItemUID?: string;
}

export class TaskRow extends ITaskRow {
    Purpose?: string;
    DueDate?: string;
    ASAPDate?: string;
    Reminders?: TaskReminder[];
    RepeatProcesss?: string;
    TitleUID?: string;
    //TaskTitleTags?:string[]

    getClassName?() {
        return 'task';
    }
    getIdField?(): string {
        return propertyOf<TaskRow>("UID");
    }
    getListUrl?(): string {
        return "Task/List";
    }
    getSaveUrl?(): string {
        return "Task/Save";
    }
    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "Task/RelatedList";
    }

}
